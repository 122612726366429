<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="selectedTab">
      <el-tab-pane label="Latest" name="Latest">
        <el-row :gutter="12">
          <el-col :span="6">
            <el-card v-show="false">  </el-card>
          </el-col>
          <el-col :span="12">
            <el-card shadow="always" center>
              <template #header>
                <span style="font-size: small">Latest Rate Card List</span>
              </template>
              <el-table :data="nameAttrList" >
                <el-table-column prop="id" label="ID" width="50"></el-table-column>
                <el-table-column prop="name" :label="$t('msg.dray.port')"></el-table-column>
                <el-table-column prop="attr" :label="$t('msg.dray.desType')" ></el-table-column>
                <el-table-column fixed="right" :label="$t('msg.rate.Operations')" width="200px">
                  <template #default="{row}">
                    <el-button type="primary" size="small" @click="getDetail(row,'latest')">{{ $t('msg.excel.show') }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card v-show="false">  </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Historical" name="Historical">
        <el-row :gutter="12">
          <el-col :span="5">
            <el-card v-show="false">  </el-card>
          </el-col>
          <el-col :span="14">
            <el-card shadow="always" center>
              <template #header>
                <span style="font-size: small">Historical Rate Card List</span>
              </template>
              <el-table :data="fbaZoneList" >
                <el-table-column prop="fcTicker" label="LSP Name"></el-table-column>
                <el-table-column prop="fromDallas" :label="$t('msg.dray.port')"></el-table-column>
                <el-table-column prop="fromLA" :label="$t('msg.dray.desType')" ></el-table-column>
                <el-table-column prop="fromNY" :label="$t('msg.rate.validity')" ></el-table-column>
                <el-table-column fixed="right" :label="$t('msg.rate.Operations')" width="200px">
                  <template #default="{row}">
                    <el-button type="primary" size="small" @click="getDetail(row,'his')">{{ $t('msg.excel.show') }}</el-button>
                    <el-button type="primary" size="small" @click="getDetail(row,'his')" disabled>Copy To New</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-col>
          <el-col :span="5">
            <el-card v-show="false">  </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Add New RateCard" name="AddNew">
        <el-card v-if="drayFormVisible">
         <el-row :gutter="12">
          <el-col :span="9">
            <el-card v-show="false">  </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always" center>
              <el-form ref="form" :model="drayForm">
                <el-form-item label="Service Port" prop="port">
                  <el-select v-model="drayForm.port" placeholder="please select port" size="small">
                    <el-option v-for="item in portList" :key="item.name" :label="item.name" :value="item.name"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Deliver Area Type" prop="toAreaType">
                  <el-select v-model="drayForm.desType" placeholder="Deliver Area Type" size="small">
                    <el-option label="City Area" value="City"></el-option>
                    <el-option label="Amazon Warehouse" value="FBA"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Active Time" required>
                  <el-col :span="11">
                    <el-date-picker type="date" placeholder="Pick a date" v-model="drayForm.startDate"
                                    value-format="YYYYMMDD" size="small"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item label="Expire Time" required>
                  <el-col :span="11">
                    <el-date-picker placeholder="Pick a time" v-model="drayForm.expireDate"
                                    value-format="YYYYMMDD" size="small"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item >
                  <el-button type="primary" @click="chooseTemp" size="small" class="pull-right">
                    Choose Template<i class="el-icon-arrow-right el-icon-right"></i>
                  </el-button>
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>
          <el-col :span="9">
            <el-card v-show="false">  </el-card>
          </el-col>
         </el-row>
        </el-card>
        <el-table :data="drayTempByPort" Style="font-size: small" border v-else>
          <el-table-column prop="id" label="Id" align="center"></el-table-column>
          <el-table-column prop="name" label="LSP Name" align="center"></el-table-column>
          <el-table-column prop="attr" label="Delivery Area Type" align="center"></el-table-column>
          <el-table-column label="Set As Latest" align="center">
            <el-radio-group v-model="latestFlag" >
              <el-radio label='Yes'>Yes</el-radio>
              <el-radio label="No">No</el-radio>
            </el-radio-group>
          </el-table-column>
          <el-table-column label="Review" align="center">
            <template #default="scope">
              <el-button type="primary" size="small" v-on:click="viewTemp(scope.row)">Review</el-button>
              <el-button type="danger" size="small" v-on:click="selectAsTemp(scope.row)">Select</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

export default defineComponent({
  name: 'drayRateCard',
  setup() {
    const router = useRouter()
    const activeName = ref('Latest')
    const nameAttrList = ref([])
    const fbaZoneList = ref([{ fcTicker: '', fromDallas: '', fromNY: '', fromLA: '' }])
    const drayForm = ref({ port: '', desType: '', startDate: '', expireDate: '' })
    const portList = ref([])
    const drayTempVisible = ref(false)
    const drayFormVisible = ref(true)
    const drayTempByPort = ref({})
    const latestFlag = ref('Yes')
    const curUser = localStorage.getItem('curUser')
    const selectedTab = () => {
      if (activeName.value === 'AddNew') {
        const reqURL = process.env.VUE_APP_BASE + '/getPortHaveDray'
        axios.get(reqURL).then((response) => {
          portList.value = response.data
        }).catch((response) => {
          console.log(response)
        })
      }
    }
    const chooseTemp = () => {
      const param = 'port=' + drayForm.value.port
      const reqURL = process.env.VUE_APP_BASE + '/drayRC/getDrayTempByPort'
      axios.post(reqURL, param).then((response) => {
        drayTempByPort.value = response.data
        drayFormVisible.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    const selectAsTemp = (row) => {
      const setInfo = latestFlag.value === 'Yes' ? 'Old' : 'Old-TimeSpecified'
      const param = 'lspName=' + fbaZoneList.value[0].fcTicker + '&pod=' + drayForm.value.port + '&toArea=' + drayForm.value.desType +
        '&activeDate=' + drayForm.value.startDate + '&expireDate=' + drayForm.value.expireDate + '&tempLSP=' + row.name + '&tempPod=' + drayForm.value.port +
        '&tempToArea=' + row.attr + '&lspType=' + setInfo
      const reqURL = process.env.VUE_APP_BASE + '/drayRC/addRCtoLSP'
      axios.post(reqURL, param).then((response) => {
        nameAttrList.value = response.data.nameAttrList
        fbaZoneList.value = response.data.fbaZoneList
        activeName.value = 'Latest'
      }).catch((response) => {
        console.log(response)
      })
    }
    onMounted(() => {
      const param = 'curUser=' + curUser
      const reqURL = process.env.VUE_APP_BASE + '/drayRC/getMyDrayRateList'
      axios.post(reqURL, param).then((response) => {
        nameAttrList.value = response.data.nameAttrList
        fbaZoneList.value = response.data.fbaZoneList
      }).catch((response) => {
        console.log(response)
      })
    })
    const viewDrayRC = (param) => {
      router.push(`/drayRC/drayRCEdit/${param}`)
    }
    const getDetail = (row, rcType) => {
      const hisRC = '&tag=' + row.fromDallas + '-' + row.fromNY + '&desType=' + row.fromLA
      const lateRC = '&tag=' + row.name + '&desType=' + row.attr
      const paramSuffix = rcType === 'his' ? hisRC : lateRC
      const myParam = 'reg;' + 'lspName=' + fbaZoneList.value[0].fcTicker + paramSuffix
      viewDrayRC(myParam)
    }
    const viewTemp = (row) => {
      const myParam = 'reg;' + 'lspName=' + row.name + '&tag=' + drayForm.value.port + '&desType=' + row.attr + ';view'
      viewDrayRC(myParam)
    }
    return { nameAttrList, fbaZoneList, activeName, portList, drayTempVisible, drayTempByPort, latestFlag, drayFormVisible, viewTemp, selectAsTemp, selectedTab, chooseTemp, getDetail, drayForm }
  }
})
</script>
